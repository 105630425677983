* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal;
  background-color: white!important;
  
}
#root {
  display: flex;
  min-height: 100vh;
  width: 100%;          /* S'assure que le container prend toute la largeur de l'écran */
  max-width: 100%;      /* Pas de limitation de largeur maximale */
  margin: 0 auto;       /* Centre le contenu si une largeur fixe est définie */
  flex-direction: column;
}

.MuiBox-root {
  display: block;
}
.MuiBox-root.css-zxdg2z {
  flex: 1;              /* Le contenu principal occupe tout l'espace restant */
}




.MuiBox-root.css-zxdg2z {
  flex-grow: 1;          /* Le contenu principal s'étire pour occuper toute la largeur restante */
  padding: 20px;
  background-color: white;
}

/* Pour les grands écrans (> 1600px) */
@media screen and (min-width: 1600px) {
  #root {
    width: 100%;         /* Assurez-vous que #root occupe toute la largeur de l'écran */
  }

  .sidebar-container {
    width: 250px;        /* Augmenter la largeur de la sidebar sur les grands écrans */
  }

  .MuiBox-root.css-zxdg2z {
    padding: 30px;       /* Plus d'espace autour du contenu principal sur les grands écrans */
  }
}

@media screen and (min-width: 1440px) {
  #root {
    width: 100%;         /* Assurez-vous que #root occupe toute la largeur de l'écran */
  }

  .sidebar-container {
    width: 250px;        /* Augmenter la largeur de la sidebar sur les grands écrans */
  }

  .MuiBox-root.css-zxdg2z {
    padding: 30px;       /* Plus d'espace autour du contenu principal sur les grands écrans */
  }
}



@media screen and (max-width: 1199px) and (min-width: 768px) {
  .sidebar-container {
    width: 200px;       /* Réduire la largeur de la sidebar sur les tablettes */
  }

  .MuiBox-root.css-zxdg2z {
    padding: 20px;      /* Ajuster le padding du contenu */
  }
}

/* Pour les petits écrans (mobiles) */
@media screen and (max-width: 767px) {
  #root {
    flex-direction: column; /* Disposition en colonne sur mobile */
  }

  .sidebar-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    transform: translateX(-100%);  /* Masquer la sidebar par défaut */
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-container.open {
    transform: translateX(0);  /* Afficher la sidebar lorsqu'elle est ouverte */
  }

  .MuiBox-root.css-zxdg2z {
    padding: 15px;
  }
}








code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h6 {
 
  
  
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size:1rem;
  
    color:rgba(0, 0, 0, 0.6);
}
.css-1bv5yfe-MuiTypography-root
{
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size:1rem !important;
  
    color:rgba(0, 0, 0, 0.6);
}
h4 {
 
  
  
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size:1rem;
  
    color:rgba(0, 0, 0, 0.6);
}
.rbc-toolbar {
 
  
  
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size:1rem;
  color:white !important;
  
    background-color:rgb(107, 91, 149) !important;
}

.rbc-btn-group > button:first-child:not(:last-child) {color:white !important;}
.rbc-toolbar button {
 
  
  
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-size:1rem;
  color:white !important;
  
    background-color:rgb(107, 91, 149) !important;
}

.rbc-month-view {background-color:white !important;font-family: "Inter", sans-serif !important;}
.rbc-event, .rbc-day-slot .rbc-background-event{   background-color:rgb(52, 182, 52) !important;}

.css-dip3t8,.css-18unl23 {
  background:  rgb(25, 25, 25) !important;
}
.css-1qkd89n >.ps-menu-button{
  background:  rgb(25, 25, 25) ;
}

.profile-img {
  width: 32px;
  height: 32px;
  border-radius: 50%; /* Makes the image circular */
  margin-right: 5px;
}

