/* Table wrapper - apply overflow here to control the scrollbars */
.table-responsive {
    overflow: auto; /* Enable both horizontal and vertical scrolling inside the table container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    margin-top: 10px; /* Add some spacing */
    max-height: 500px; /* Set a max height for vertical scrolling */
    max-width: 100%; /* Ensure the table fits within the screen */
}

/* Table styling */
.custom-table {
    font-family: 'Lato', sans-serif;
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Ensure rounded corners work */
    min-width: 600px; /* Ensure table is wide enough to not break */
}

/* Table header sticky behavior */
.custom-table th {
    background-color: rgba(107, 91, 149, 0.9);
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    padding: 12px 15px;
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure header stays above other content */
}

/* Table cells */
.custom-table th,
.custom-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
}

/* Hover effect on rows */
.custom-table tr:hover {
    background-color: #f5f5f5;
}

/* Scrolling effect for both vertical and horizontal inside the table */
.table-responsive::-webkit-scrollbar {
    width: 12px; /* Set scrollbar width */
}

.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
    .custom-table {
        font-size: 14px;
    }

    .custom-table th,
    .custom-table td {
        padding: 8px;
    }

    .table-responsive {
        max-height: 400px; /* Adjust max height for smaller screens */
    }
}
