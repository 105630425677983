/* StatusBox.css */
.status-box {
    display: inline-block;
    padding: 5px 15px;
    border: 2px solid;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
  }
  