.sidebar-container {
  min-width: '20%';
  display: flex;
  position: fixed; /* Fix position of sidebar */
  display: flex;
  position: relative; /* To position the collapse icon correctly */
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) !important; 
  top: 0;
  left: 0;

}


.custom-sidebar {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) !important; /* Celestial gradient */
  width: 300px;
  transition: width 0.8s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #dfe9f3;
  height: "100%"; /* Set sidebar height to full viewport height */
}

.custom-sidebar.collapsed {
  width: 80px;
}


.collapse-icon {
  position: absolute;
  top: 20px;
  left: 110%; /* Move to the right of the sidebar */
  transform: translateX(-50%); /* Center the icon */
  cursor: pointer;
  z-index: 10; /* Ensure it's above other elements */
}

.pro-menu-item {
  padding: 10px;
  font-weight: normal; /* Remove bold font */
}

.pro-menu-item:hover {
  background-color: rgba(107, 91, 149, 0.1); /* Light color on hover */
}

.pro-menu-item.active {
  background-color: black !important; /* Active background */
}
