.add-campaign-button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center; /* Center the icon vertically */
    margin-bottom: 20px; /* Space between button and table */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .add-campaign-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  